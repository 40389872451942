

import ProblemListItem from "./ProblemListItem.vue";
import Adsense from "../../../../components/anuncis/Adsense.vue";

export default {
    components: {Adsense, ProblemListItem},
    props: {
        problems: {
            required: true
        },
        selectedProblem: {
            required: true
        }
    },
    mounted() {
        // Espera un segon per ensenyar la llista i així no fer dos requests de problemes seguits al fer instersect
        setTimeout(() => {
            this.showList = true
        }, 1000)
    },
    data() {
        return {
            showList: false
        }
    },
    computed: {
        responsiveCols() {
            const breakpoint = this.$vuetify.breakpoint;

            const cols = {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 4,
                xl: 4
            }

            return cols[breakpoint.name]
        }
    },
    methods: {
        intersectInfiniteScroll(entries, observer) {
            if (entries[0].isIntersecting) {
                console.log("intersectInfiniteScroll")
                this.$emit('fetchProblems')
            }
        },
    }
}
